import React, { Fragment, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import Layout from "layout"
import Container from "layout/Container"
import Section from "elements/Section"
import Message from "elements/Message"
import ActionButtons from "elements/ActionButtons"

import { generateFormField } from "elements/Form/services/form"
import { useSectionFormFields } from "../../ConsultationContext/hooks/useSectionFormFields"

import { isBrowser, isObjectEmpty } from "services/general"
import { generateInitialValues } from "../../../../services/context"

import { ConsultationContext } from "../../ConsultationContext/ConsultationContext"

const Support = ({ pageContext }) => {
  const { consultationState, consultationDispatch } = useContext(
    ConsultationContext
  )

  let { backPath, progress, numberOfPages } = pageContext

  let { sectionFormFields, validationSchema } = useSectionFormFields({
    formFields: pageContext?.formFields,
  })

  const handleSubmit = async (values) => {
    await consultationDispatch({
      type: "SAVE_MEDICAL_HISTORY",
      payload: {
        ...values,
      },
    })

    navigate(pageContext.nextPath)
  }

  useEffect(() => {
    if (isBrowser()) {
      consultationDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    }
  }, [consultationDispatch])

  if (consultationState.channel === "") delete consultationState.channel

  let initialValues = {
    ...generateInitialValues({
      fields: pageContext.formFields,
    }),
    ...consultationState,
  }

  const handleFollowUpOnChange = ({ field, setFieldValue }) => {
    // if not, reset both field values and state values
    for (let i = 0; i < field.followUpQuestions.length; i++) {
      setFieldValue(field.followUpQuestionsName[i], "")
    }
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      isPrivate
      progress={(progress / numberOfPages) * 100}
      numberOfPages={numberOfPages}
    >
      <Container isCentered>
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                >
                  {section?.message && (
                    <Message color="light">{section?.message}</Message>
                  )}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      if (field?.followUpQuestions.length)
                        field.onChange = (e) => {
                          values = handleFollowUpOnChange({
                            field,
                            formFields: section?.fields,
                            setFieldValue,
                            values,
                          })
                        }

                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: field,
                            values,
                            setFieldValue,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}

              {!isObjectEmpty(errors) && (
                <Message>
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{
                  label: (
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.0664 26.5599L11.3731 17.8666C10.3464 16.8399 10.3464 15.1599 11.3731 14.1333L20.0664 5.43994"
                        stroke="#EE7423"
                        stroke-width="2.66667"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ),
                  link: backPath,
                }}
                submit={{ label: `Next: ${pageContext.nextPathTitle}` }}
                loading
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default Support
